<template>
  <div style="overflow: scroll; background-color: #f5f5f5">
    <div class="main">
      <!-- 订单状态 -->
      <div
        class="top-container"
        :style="{ backgroundImage: `url(${topHeaderStyle.backgroundImage})` }">
        <div class="state">
          <span class="state-name" :style="{ color: topHeaderStyle.color }">{{
            detail.orderStatus
          }}</span>
          <div class="status-tips" v-if="showTips">
            <template v-if="isToday"> 今日为体检日，请及时前往</template>
            <template v-else>
              距离体检还有<span style="color: #f5ad00">{{ diffDate }}</span
              >天，请按时前往
            </template>
          </div>
        </div>
      </div>

      <!-- 医院信息 -->
      <div class="hosipital">
        <template v-if="!hideHospital">
          <div class="hosipital-info">
            <img class="hosipital-img" :src="detail.logoUrl" alt="" />
            <div class="hosipital-right">
              <div class="hosipital-name van-ellipsis">
                {{ detail.hospitalName }}
              </div>
              <div class="tag">
                <span class="tag-item">{{ detail.hospitalCategory }}</span>
                <span class="tag-item">{{
                  detail.advanceDay
                    ? `提前${detail.advanceDay}天约`
                    : "当天可约"
                }}</span>
              </div>
            </div>
          </div>
          <van-divider />
        </template>
        <div class="appointment-time">
          <span>
            <img
              class="appointment-time-icon"
              src="@/assets/icon-time.png"
              alt="" />
            预约时间
          </span>
          <span style="color: #f5ad00"
            >{{ detail.orderItems[0]?.appointmentDate }}
            {{ detail.appointmentTime }}</span
          >
        </div>
      </div>

      <!-- 套餐信息 -->
      <div class="base-info">
        <div class="base-info-header" @click="showProject = true">
          体检套餐项目
          <img src="@/assets/icon-right-primary.png" alt="" />
        </div>
        <div class="base-product">
          <span class="bold">{{ detail.orderItems[0]?.productName }}</span>
          <span class="bold" style="margin-left: 20px"
            >¥{{ detail.orderItems[0]?.proudctPrice }}</span
          >
        </div>
        <div class="product-tag">
          <span class="product-tag-item"
            >{{ detail.projectCount }}个检查项目</span
          >
          <span v-if="detail.diseaseCount" class="product-tag-item"
            >筛查{{ detail.diseaseCount }}个疾病</span
          >
        </div>

        <template
          v-if="
            detail.orderItems[0]?.hospitalAdditionalPackageProjects?.length
          ">
          <div class="add-item-header">
            <span class="bold">套餐加项</span>
            <span class="bold"
              >￥{{ detail.orderItems[0].totalPackagePrice }}</span
            >
          </div>
          <div
            class="add-item"
            v-for="item in detail.orderItems[0]
              .hospitalAdditionalPackageProjects"
            :key="item.projectNo">
            <span>{{ item.projectName }}</span>
          </div>
        </template>

        <div class="product-price" v-if="detail.discountAmount">
          <span class="bold">订单抵扣金额</span>
          <span class="bold">¥{{ detail.discountAmount }}</span>
        </div>

        <div class="product-price">
          <span class="bold">订单实付金额</span>
          <span class="bold"
            >¥{{ detail.payee === 1 ? 0 : detail.paymentAmount }}</span
          >
        </div>
      </div>

      <!-- 订单信息 -->
      <div class="order-info">
        <div class="order-info-item">
          <span>预约姓名</span>
          <span>{{ detail.orderItems[0]?.memberName || "--" }}</span>
        </div>
        <div class="order-info-item">
          <span>订单编号</span>
          <span>{{ detail.orderNo || "--" }}</span>
        </div>
        <div class="order-info-item">
          <span>支付单号</span>
          <span>{{ detail.thirdPaymentTradeNo || "--" }}</span>
        </div>
        <div class="order-info-item">
          <span>下单时间</span>
          <span>{{ detail.createDate || "--" }}</span>
        </div>
        <div class="order-info-item">
          <span>付款时间</span>
          <span>{{ detail.paymentDate || "--" }}</span>
        </div>
        <div class="order-info-item">
          <span>联系电话</span>
          <span>{{ detail.orderItems[0]?.mobile || "--" }}</span>
        </div>
      </div>

      <!-- 体检须知 -->
      <div class="notice">
        <div class="notice-header">体检须知</div>
        <div class="notice-desc">
          1.下单成功后将会收到预约短信通知，请及时确认信息；
          <br />
          2.订单有效期为1年，过期不退；
          <br />
          3.预约成功后如需改期请及时联系客服；
          <br />
          4.如遇医院升级、变更等不可抗力原因将会进行改期，具体以公告/通知为准。
        </div>
      </div>
    </div>

    <van-popup
      v-model="showProject"
      closeable
      position="bottom"
      round
      get-container="body">
      <projectInfo
        :projectInfo="detail.orderItems[0]?.productJson"></projectInfo>
    </van-popup>

    <div class="footer-container" v-if="fromatButtonName(detail).length">
      <div class="footer">
        <div
          :class="item.className"
          class="footer-button"
          v-for="item in fromatButtonName(detail)"
          :key="item.value"
          @click="buttonClick(item.value)">
          {{ item.value }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
import projectInfo from "@/views/components/goodDetail/projectInfo.vue";

import defaultBg from "@/assets/order-detail-bg-default.png";
import closeBg from "@/assets/order-detail-bg-close.png";
import primaryBg from "@/assets/order-detail-bg-primary.png";
import { GetOrderItem, CancelOrder, Repay, QueryStatus } from "@/api";
import { Dialog } from "vant";
import { isWechat } from "@/utils/index.js";

export default {
  name: "order-detail",
  components: {
    projectInfo,
  },
  data() {
    return {
      defaultBg,
      closeBg,
      primaryBg,
      id: 1,
      detail: { orderItems: [] },
      payInfo: {},
      showProject: false,
      timer: null,
    };
  },
  computed: {
    topHeaderStyle() {
      const status = this.detail.status;
      let params = {};
      if ([0, 1, 2, 3, 4].includes(status)) {
        params = {
          backgroundImage: this.defaultBg,
          color: "#FFB910",
        };
      } else if ([5, 6].includes(status)) {
        params = {
          backgroundImage: this.primaryBg,
          color: "#00C4B3",
        };
      } else if (status === 8) {
        params = {
          backgroundImage: this.closeBg,
          color: "#878D99",
        };
      }
      return params;
    },
    showTips() {
      const date = this.detail.orderItems[0]?.appointmentDate;
      dayjs.extend(isSameOrBefore);
      return (
        [2, 3].includes(this.detail.status) &&
        dayjs().isSameOrBefore(date, "day")
      );
    },
    diffDate() {
      const date = this.detail.orderItems[0]?.appointmentDate;
      return dayjs(date).diff(dayjs(), "day") + 1;
    },
    isToday() {
      const date = this.detail.orderItems[0]?.appointmentDate;
      return dayjs().isSame(date, "day");
    },
    dictSellingPointKeys() {
      const sellingPoints = this.detail.orderItems[0]?.sellingPoints || "";
      return sellingPoints.split(",").splice(0, 2);
    },
    hideHospital() {
      return this.$store.state.hideHospital;
    },
  },
  watch: {
    "detail.status"(val) {
      console.log(val, 3333);
      if (val === 0) {
        this.timer = setInterval(() => {
          this.reQueryStatus();
        }, 3000);
      } else {
        clearInterval(this.timer);
      }
    },
  },
  beforeCreate() {
    // 页面创建时设置
    document.querySelector("body").style.backgroundColor = "#f5f5f5";
  },
  created() {
    this.id = this.$route.params.id;
    // this.$setTitle("订单详情");
    this.$store.commit("setTitle", "订单详情");
    this.getDetail();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    async getDetail() {
      let data = await GetOrderItem({ orderNo: this.id });
      this.detail = data;
      console.log(this.detail);
    },
    fromatButtonName(item) {
      const { status, payee } = item;
      let list = [];
      if (status === 0) {
        list = [
          {
            value: "取消订单",
            className: "btn-default",
          },
          {
            value: "立即付款",
            className: "btn-primary",
          },
        ];
      } else if (status === 1 || status === 2) {
        return [
          {
            value: "修改预约",
            className: "btn-default",
          },
        ];
      }
      if ([1, 2, 3].includes(status) && payee !== 1) {
        list.unshift({
          value: "申请退款",
          className: "btn-default",
        });
      }
      console.log(list, 333);
      return list;
    },
    buttonClick(str) {
      if (str === "立即付款") {
        this.handlerPay();
      } else if (str === "取消订单") {
        this.cancelOrder(this.detail);
      } else if (str === "修改预约") {
        this.$router.push(
          "/edit-appointment/" +
            this.id +
            "?hospitalProductId=" +
            this.detail.orderItems[0].hospitalProductId
        );
      } else if (str === "申请退款") {
        this.$router.push("/refund-detail/" + this.id);
      }
    },
    //取消订单
    cancelOrder(item) {
      console.log(item.orderItems[0].orderDetlId);
      Dialog.confirm({
        title: "取消订单",
        message: "是否确定取消订单？",
        confirmButtonColor: "#00c4b3",
      })
        .then(async () => {
          // on confirm
          const res = await CancelOrder({
            orderDetailId: item.orderItems[0].orderDetlId,
            refundReasonCode: "9",
          });
          this.getDetail();
        })
        .catch(() => {
          // on cancel
        });
    },
    async handlerPay() {
      let data = await Repay({
        orderNo: this.id,
        appId: process.env.VUE_APP_APPID,
        openId: this.$store.state.openid,
        paymentType: 0, // 支付平台 Tenpay = 0 Alipay = 1
        paymentMethod: isWechat() ? 1 : 2, // JsApi = 1  H5 = 2 Pc = 3
      });
      if (data.h5Url) {
        location.href = data.h5Url;
      } else {
        this.payInfo = data;
        this.weixinPay();
      }
    },
    onBridgeReady() {
      const _this = this;
      console.log(_this.payInfo);
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: _this.payInfo.appId, //公众号名称，由商户传入
          timeStamp: _this.payInfo.timestamp, //时间戳，自1970年以来的秒数
          nonceStr: _this.payInfo.nonceStr, //随机串
          package: _this.payInfo.prepayIdPackage,
          signType: "RSA", //微信签名方式：
          paySign: _this.payInfo.signature, //微信签名
        },

        async (res) => {
          const { payStatus } = await this.QueryStatus();
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            if (payStatus) {
              _this.$toast("支付成功");
              _this.getDetail();
            }
            // this.gametwoApplyWx()
            // webPayReturnToOrder({gameOrderId: _this.payInfo.gameOrderId})
            // _this.$router.push('/success')
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          } else {
            console.log(res.err_msg, "wx_pay");
            _this.$toast("支付失败,请重新支付");
          }
        }
      );
    },
    weixinPay() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    async QueryStatus() {
      if (!this.id) return;
      let data = await QueryStatus({ orderNo: this.id }).catch(() => {
        clearInterval(this.timer);
      });
      return data;
    },
    async reQueryStatus() {
      const { payStatus } = this.QueryStatus();
      if (payStatus) {
        this.getDetail();
      }
    },
  },
};
</script>

<style scoped>
.top-container {
  display: flex;
  align-items: center;
  background: 100% 100% no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100px;
}

.state {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.state-name {
  font-size: 20px;
  font-weight: bold;
  color: #878d99;
}

.status-tips {
  margin-top: 10px;
  font-size: 14px;
  color: #475266;
  line-height: 20px;
}

.main {
  padding: 10px;
}

.hosipital {
  margin-top: 10px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
}

.hosipital-info {
  display: flex;
  width: 100%;
}

.hosipital-img {
  margin-right: 8px;
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.hosipital-right {
  flex: 1;
  overflow: hidden;
}

.hosipital-name {
  font-size: 16px;
  color: #000;
  line-height: 24px;
}

.tag {
  display: flex;
  align-items: center;
}

.tag-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 4px;
  font-size: 13px;
  font-weight: bold;
  background-color: #fff;
  color: #00c4b3;
  border: 1px solid rgba(49, 207, 150, 0.2);
  border-radius: 4px;
}

.appointment-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.appointment-time-icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

.base-info {
  margin-top: 10px;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
}

.base-info-header {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: #333333;
}

.base-info-header img {
  margin-left: 5px;
  width: 8px;
  height: 8px;
}

.base-product {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  font-size: 15px;
  color: #333;
  line-height: 24px;
}

.product-tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
}

.product-tag-item,
.product-keys-item {
  padding: 0 4px;
  margin-right: 4px;
  height: 20px;
  line-height: 20px;
  color: #0091ff;
  font-size: 12px;
  background: rgba(60, 196, 255, 0.1);
  border-radius: 4px;
}
.product-keys-item {
  color: #ffb470;
  background: rgba(255, 180, 112, 0.1);
}

.add-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  height: 28px;
  font-size: 15px;
  color: #333;
  font-weight: bold;
}

.add-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  color: #878d99;
}

.add-item:not(:first-child) {
  margin-top: 10px;
}

.product-price {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 15px;
  color: #333;
}

.order-info {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 8px;
}

.order-info-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 36px;
  font-size: 14px;
  color: #6d7175;
}

.notice {
  margin-top: 10px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  line-height: 20px;
}

.notice-header {
  font-size: 14px;
  color: #000;
}

.notice-desc {
  margin-top: 8px;
  color: #6d7175;
  font-size: 14px;
}

.footer-container {
  height: 54px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 8px;
  width: 100%;
  background-color: #fff;
  height: 54px;
  border-top: 1px solid #e1e3e5;
}

.btn-primary,
.btn-default {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 46%;
  height: 36px;
  border-radius: 18px;
  font-size: 15px;
}

.btn-primary {
  background-color: #00c4b3;
  color: #fff;
}

.btn-default {
  background-color: #fff;
  border: 1px solid #00c4b3;
  color: #6d7175;
}

.footer-button:not(:first-child) {
  margin-left: 16px;
}
</style>
