var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("您有权在橄榄医院上享受我们的平台上的合作伙伴")]),_c('ins',{staticStyle:{"-aw-revision-author":"'ting'","-aw-revision-datetime":"'2023-04-13T16:15:17'"}},[_c('span',{staticStyle:{}},[_vm._v("【"+_vm._s(_vm.hospitalInfo.name || _vm.hospitalInfo.hospitalName)+"】")])]),_c('span',{staticStyle:{}},[_vm._v("提供的服务以及相关配套服务，具体的服务内容及服务主体以产品页面的展示及实际提供者为准。")])]),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27),_vm._m(28),_vm._m(29),_vm._m(30),_vm._m(31),_vm._m(32),_vm._m(33),_vm._m(34),_vm._m(35),_vm._m(36),_vm._m(37),_vm._m(38),_vm._m(39),_vm._m(40),_vm._m(41),_vm._m(42),_vm._m(43),_vm._m(44),_vm._m(45),_vm._m(46),_vm._m(47),_vm._m(48),_vm._m(49),_vm._m(50),_vm._m(51),_vm._m(52),_vm._m(53),_vm._m(54),_vm._m(55),_vm._m(56),_vm._m(57),_vm._m(58),_vm._m(59),_vm._m(60),_vm._m(61),_vm._m(62),_vm._m(63),_vm._m(64),_vm._m(65),_vm._m(66),_vm._m(67),_vm._m(68),_vm._m(69),_vm._m(70),_vm._m(71),_vm._m(72),_vm._m(73),_vm._m(74),_vm._m(75),_vm._m(76),_vm._m(77),_vm._m(78),_vm._m(79),_vm._m(80),_vm._m(81),_vm._m(82),_vm._m(83)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"Title",staticStyle:{"font-size":"24pt","font-weight":"bold","margin-top":"7.8pt","margin-bottom":"7.8pt","text-align":"left","line-height":"normal"}},[_c('span',{staticStyle:{}},[_vm._v("用户服务协议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","text-align":"right","font-size":"11pt"}},[_c('span',{staticStyle:{"color":"#333333","-aw-import":"spaces"}},[_vm._v(" ")]),_c('span',{staticStyle:{"color":"#333333","-aw-import":"spaces"}},[_vm._v("  ")]),_c('span',{staticStyle:{"color":"#333333"}},[_vm._v("版本号：00003")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","text-align":"right","font-size":"11pt"}},[_c('span',{staticStyle:{"color":"#333333","-aw-import":"spaces"}},[_vm._v(" ")]),_c('span',{staticStyle:{"color":"#333333"}},[_vm._v("更新时间：")]),_c('span',{staticStyle:{}},[_vm._v("2024年8月21日")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","text-align":"right"}},[_c('span',{staticStyle:{"color":"#171a1d","-aw-import":"ignore"}},[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","font-size":"14pt"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("感谢您选择使用我们的产品和服务！")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("“我们”是广州橄榄医院有限公司（下称“橄榄医院”），注册地位于广州市白云区金沙街沙凤三路3号B馆B129、B438。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("“我们的服务”是指我们通过我们平台的客户端")]),_c('span',{staticStyle:{}},[_vm._v("（包括但不限于H5网页、小程序、PC端）")]),_c('span',{staticStyle:{}},[_vm._v("向您提供的互联网平台信息技术服务。我们的服务由我们和我们分布于各地的各分支机构、关联公司及合作伙伴共同为您提供。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("为使用我们的服务（指我们通过我们的平台向您提供的互联网平台信息技术服务，以下简称“本服务”），您应当阅读并遵守《用户服务协议》（以下简称“本协议”）。您在加入我们之前，已确认您具有注册和使用我们服务相应的民事行为能力，请认真阅读本协议，确保您充分理解本协议中各条款，并慎重选择接受或不接受本协议。")]),_c('span',{staticStyle:{"font-size":"10.5pt","font-weight":"bold","color":"#171a1d"}},[_vm._v("除非您已充分阅读并接受本协议所有条款，否则您无权使用我们的服务。您对本服务的登录、查看等使用行为即视为您已阅读并同意本协议的约束，且完全认可、接受橄榄医院的统一管理。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt"}},[_c('span',{staticStyle:{"font-weight":"bold","color":"#171a1d"}},[_vm._v("如果您因年龄（未满18周岁）、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称\"监护人\"）的陪同下阅读和判断是否同意本协议，并特别注意未成年人使用条款。")]),_c('span',{staticStyle:{"color":"#171a1d"}},[_vm._v("如果您是中国大陆地区以外的用户，您订立或履行本协议还需要同时遵守您所属和/或所处国家或地区的法律。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","text-align":"left","widows":"2","orphans":"2","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("如果您是中国大陆地区以外的用户，您订立或履行本协议还需要同时遵守您所属和/或所处国家或地区的法律。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-left":"21pt","margin-bottom":"7.8pt","font-size":"12pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("一、")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("用户资格")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("您确认，在您开始使用我们的服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果，且橄榄医院有权注销您的账号，并向您及您的监护人索偿。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-left":"21pt","margin-bottom":"7.8pt","font-size":"12pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("二、")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("权利与义务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticStyle:{"font-size":"11pt","margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","text-align":"justify","line-height":"normal","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"color":"#000000"}},[_c('span',[_vm._v("（一）")])])]),_c('span',{staticStyle:{"color":"#000000"}},[_vm._v("用户权利及与义务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("1．")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("用户注册")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-left":"21pt","margin-bottom":"7.8pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("若您注册成为橄榄医院的会员，您应接受如下的约定：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（1）")])])]),_c('span',{staticStyle:{}},[_vm._v("申请会员时，您应当提供真实、准确、即时、完整及有效的注册信息，保证不以他人资料在橄榄医院进行注册或认证；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（2）")])])]),_c('span',{staticStyle:{}},[_vm._v("维护并及时更新注册信息以保持其真实、准确、即时、完整及有效。若您提供的信息不真实、不准确、不完整，橄榄医院有权暂停或终止会员服务；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'3'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（3）")])])]),_c('span',{staticStyle:{}},[_vm._v("如果因您所提供的注册资料不合法、不真实、不准确或未及时更新，从而导致相关法律责任或不利后果的，您将承担相应的法律责任及不利后果。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'4'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("（4）")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("您已知悉，在橄榄医院注册过程中，因安全性、内部管理等原因，部分初始注册资料可能将无法再次更改。因此，您应谨慎核查此部分注册资料，确保正确填写。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("2．")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("用户权利")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("您拥有在我们的平台上使用自行注册的您名和密码之权利，但不得以任何形式擅自转让或授权他人使用自己的帐号。您如发现其他用户有违法或违反本服务协议的行为，可以向橄榄医院进行反映和要求处理。")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("您清楚知悉并同意授权我们的平台接收医院为您出具的体检报告，以方便您进行线上体检报告查询。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'3'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("3．")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("用户义务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("您单独承担发布内容的责任。您对服务的使用是根据所有适用于橄榄医院的国家法律、地方法律和国际法律标准的。您应遵守相关法律法规，不应从事如下违反法律法规的规定，影响正常服务提供或损害他人合法利益的行为：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（1）")])])]),_c('span',{staticStyle:{}},[_vm._v("不应利用橄榄医院或相关服务危害国家安全、破坏政治稳定、泄露国家秘密，不侵犯国家、社会、集体利益和第三方的合法权益，不从事违法犯罪活动，不设立用于实施诈骗，传授犯罪方法，制作或者销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组，不利用网络发布涉及实施诈骗，制作或者销售违禁物品、管制物品以及其他违法犯罪活动的信息；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（2）")])])]),_c('span',{staticStyle:{}},[_vm._v("不应制作、发布、复制、查阅和传播、存储、链接下列信息：反对宪法所确定的基本原则的；危害国家安全，泄露国家秘密，颠覆国家政权，推翻社会主义制度，煽动分裂国家，破坏国家统一的；损害国家荣誉和利益的；煽动民族仇恨、民族歧视，破坏民族团结的；破坏国家宗教政策，宣扬邪教和封建迷信的；煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；捏造、散布谣言，侵犯他人权利，扰乱经济、社会秩序，破坏社会稳定的；散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；侮辱或者诽谤他人，侵害他人合法权益的；宣扬恐怖主义、极端主义的；违背当地风俗习惯的；含有法律、行政法规禁止的其他内容的；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'3'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（3）")])])]),_c('span',{staticStyle:{}},[_vm._v("不应从事下列危害计算机信息网络安全的活动：对网络服务及相关软硬件设施进行破解、破坏、删除、修改或者增加的；对计算机信息网络中存储或者传输的数据和应用程序进行删除、修改或者增加的；使用软件或硬件等方式窃取他人口令、非法入侵他人计算机系统；故意制作、传播计算机病毒等破坏性程序的；其他危害计算机信息网络安全的活动；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'4'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（4）")])])]),_c('span',{staticStyle:{}},[_vm._v("不应擅自复制和使用网络上未公开和未授权的文件。除相关著作权人明示同意，不应在网络中擅自破解、传播、下载或复制第三人享有著作权的软件或进行其他任何侵害他人知识产权的活动；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'5'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（5）")])])]),_c('span',{staticStyle:{}},[_vm._v("不应发布其他违反法律法规、政策及公序良俗、社会公德或干扰微信和/或支付宝正常运营和侵犯其他用户或第三方合法权益内容的信息。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'4'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("4．")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("责任承担")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("如您在使用我们的服务过程中涉及上述行为中的一项或多项，则需要对自己的行为承担法律责任。承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在橄榄医院首先承担了因您的行为导致的行政处罚或侵权损害赔偿责任等损失（含诉讼费、律师费等相关法律程序费用）后，您应立即给予橄榄医院等额的赔偿。此外，根据具体违法违规情形，橄榄医院有权作出独立判断，立即暂停或终止对您提供部分或全部服务，包括锁定、注销、删除帐号等措施。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("您知悉并理解，如果橄榄医院发现您的上述违法违规行为，依据相关法律法规的规定，橄榄医院有权或有义务立即停止相关服务，删除或屏蔽相关违规信息、服务评价，并视情况进行调查取证，保存有关记录，或向国家有关机关举报。同时，橄榄医院有权自主删除、屏蔽含有该内容的任何数据信息。橄榄医院将根据国家相关法律法规要求，对依法删除、屏蔽的数据信息予以记录、保存。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticStyle:{"font-size":"11pt","margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","text-align":"justify","line-height":"normal","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"color":"#000000"}},[_c('span',[_vm._v("（二）")])])]),_c('span',{staticStyle:{"color":"#000000"}},[_vm._v("橄榄医院的权利与义务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("1．")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("橄榄医院的权利")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（1）")])])]),_c('span',{staticStyle:{}},[_vm._v("橄榄医院在必要时有权修改服务条款。如果您继续享有橄榄医院的服务，则被视为接受服务条款变动。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（2）")])])]),_c('span',{staticStyle:{}},[_vm._v("橄榄医院有权在一定的情形下以网络发布形式公布用户在使用橄榄医院提供的服务过程中的违法行为。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'3'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（3）")])])]),_c('span',{staticStyle:{}},[_vm._v("橄榄医院有权在不通知用户的前提下对用户在橄榄医院发布的下列各类信息进行删除或采取其它限制性措施：以炒作信用为目的的信息；存在欺诈等恶意或虚假内容的信息；违反公共利益或可能严重损害橄榄医院和其它用户合法利益的信息。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("2．")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("橄榄医院的义务")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（1）")])])]),_c('span',{staticStyle:{}},[_vm._v("橄榄医院通过国际互联网为用户提供网络服务，并承担本协议和服务条款规定的对用户责任和义务。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（2）")])])]),_c('span',{staticStyle:{}},[_vm._v("橄榄医院有义务对用户在注册使用橄榄医院中所遇到的与注册或服务有关的问题及反映的情况作出相应的回复。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'3'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（3）")])])]),_c('span',{staticStyle:{}},[_vm._v("橄榄医院发给用户的通告一般应通过重要页面的公告提示或其他常规通知形式进行提示。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-left":"21pt","margin-bottom":"7.8pt","font-size":"12pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'3'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("三、")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("服务及免责声明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("（一）")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("服务内容")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("您可通过橄榄医院接受多种服务，具体的服务内容、服务方式、服务对象及其他的要求以橄榄医院针对具体的服务发布的章程、服务条款和操作规则具体执行。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("（二）")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("免责声明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("您明确理解和同意橄榄医院对如下事宜不承担任何法律责任的声明：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","widows":"2","orphans":"2","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("1．")])])]),_c('span',{staticStyle:{}},[_vm._v("我们的内容由橄榄医院提供，橄榄医院在对平台进行合理管理和维护的同时，不对任何个人和组织直接或间接依据我们提供的信息而采取的任何行为承担责任。我们的一切内容均不得被视为在提供建议或做出推荐，不得被视作任何决定或行为的依据；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","widows":"2","orphans":"2","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("2．")])])]),_c('span',{staticStyle:{}},[_vm._v("您充分了解并同意，您必须为自己注册账户下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对我们的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。橄榄医院无法且不会对因前述风险而导致的任何损失或损害承担责任；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","widows":"2","orphans":"2","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'3'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("3．")])])]),_c('span',{staticStyle:{}},[_vm._v("您违反有关法律或者本协议之规定，使橄榄医院遭受任何损失，受到任何第三方的索赔，或任何行政管理部门的处罚，您应对橄榄医院提供补偿，包括但不限于合理的律师费用；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","widows":"2","orphans":"2","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'4'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("4．")])])]),_c('span',{staticStyle:{}},[_vm._v("尽管我们尽力确保本网站所有信息的正确性和适时性，但并不能保证其为完全准确和最新，我们对本网站信息的准确性、完整性和真实性不作任何承诺；对信息的迟延、失误和遗漏不承担任何责任。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-left":"21pt","margin-bottom":"7.8pt","font-size":"12pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'4'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("四、")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("知识产权")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("橄榄医院拥有本平台内所有内容的知识产权，包括文字、软件、声音、相片、录像、图表；广告中的全部内容；橄榄医院为用户提供的其他信息，所有这些内容受著作权、商标权、专利权和其它财产所有权法律的保护。任何人只有在获得橄榄医院的书面授权之后才能使用这些内容,，否则不能擅自复制、修改、制作衍生产品、进行还原工程、反向编译或以其它方式转移任何权利，或用于任何商业目的。橄榄医院的所有内容版权归原文作者和橄榄医院共同所有，任何人需要转载橄榄医院的内容，必须获得原文作者或橄榄医院的授权。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-left":"21pt","margin-bottom":"7.8pt","font-size":"12pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'5'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("五、")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("用户信息和隐私保护")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("（一）")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("信息收集和存储")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"color":"#171a1d"}},[_c('span',[_vm._v("1．")])])]),_c('span',{staticStyle:{"color":"#171a1d"}},[_vm._v("个人信息的收集：若您注册并使用我们的服务，我们可能会根据合作的医疗机构的授权收集您")]),_c('span',{staticStyle:{"font-weight":"bold","color":"#171a1d"}},[_vm._v("姓名、证件号码、婚姻状况、预约时间、确认体检项目，可能还会收集您的性别、出生日期等信息")]),_c('span',{staticStyle:{"color":"#171a1d"}},[_vm._v("。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"color":"#171a1d"}},[_c('span',[_vm._v("2．")])])]),_c('span',{staticStyle:{"color":"#171a1d"}},[_vm._v("我们将按照法律法规规定,将境内收集的您的个人信息存储于中国境内。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("（二）")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("信息安全和《用户隐私政策》")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"color":"#171a1d"}},[_c('span',[_vm._v("1．")])])]),_c('span',{staticStyle:{"color":"#171a1d"}},[_vm._v("我们将采取合理措施保护您的个人信息，以防止信息的丢失、盗用、未经授权访问或披露。为确保您个人信息的安全,我们具备严格的信息安全规定和流程、专门的信息安全团队以及信息安全应急预案等。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"color":"#171a1d"}},[_c('span',[_vm._v("2．")])])]),_c('span',{staticStyle:{"color":"#171a1d"}},[_vm._v("我们充分尊重您在个人信息及隐私方面享有的权益，您可以阅读《用户隐私政策》进一步以了解您在使用产品或服务时，我们收集和使用您的个人信息的目的、方式和范围，您对您的个人信息的权利，以及我们为保护信息安全所采取的安全保护措施。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-left":"21pt","margin-bottom":"7.8pt","font-size":"12pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'6'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("六、")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("协议变更和终止")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticStyle:{"font-size":"11pt","margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","text-align":"justify","line-height":"normal","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"color":"#000000"}},[_c('span',[_vm._v("（一）")])])]),_c('span',{staticStyle:{"color":"#000000"}},[_vm._v("协议变更")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("根据国家法律法规变化及网站运营需要，橄榄医院有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本站上即生效，并代替原来的协议。您可随时登录查阅最新协议；您有义务不时关注并阅读最新版的协议及网站公告。如您不同意更新后的协议，可以且应立即停止接受橄榄医院依据本协议提供的服务；如您继续使用本网站提供的服务的，即视为同意更新后的协议。橄榄医院建议您在使用本站之前阅读本协议及本站的公告。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticStyle:{"font-size":"11pt","margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","text-align":"justify","line-height":"normal","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"color":"#000000"}},[_c('span',[_vm._v("（二）")])])]),_c('span',{staticStyle:{"color":"#000000"}},[_vm._v("协议终止情形")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("1．")])])]),_c('span',{staticStyle:{}},[_vm._v("如因橄榄医院相关服务器或系统维护或升级的需要而需中断或暂停提供任何产品和服务的，橄榄医院将尽可能事先进行通告。同时您可随时单方终止使用产品和服务，您终止使用无需特别通知橄榄医院。您主动终止使用产品和服务时，本协议终止。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("2．")])])]),_c('span',{staticStyle:{}},[_vm._v("在下列情况下，橄榄医院可以通过注销用户账号方式，单方面解除本协议：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（1）")])])]),_c('span',{staticStyle:{}},[_vm._v("在用户违反本协议相关规定时，橄榄医院有权暂停或终止向该用户提供服务。如该用户在橄榄医院暂停或终止提供服务后，再一次直接或间接或以他人名义注册为橄榄医院网站用户的，则橄榄医院有权再次暂停或终止向该用户提供服务；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（2）")])])]),_c('span',{staticStyle:{}},[_vm._v("本协议修改或更新时，如用户表示不愿接受新的服务协议的，橄榄医院有权终止向该用户提供服务；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'3'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（3）")])])]),_c('span',{staticStyle:{}},[_vm._v("橄榄医院认为需终止提供服务的其他情况。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticStyle:{"font-size":"11pt","margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","text-align":"justify","line-height":"normal","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'3'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"color":"#000000"}},[_c('span',[_vm._v("（三）")])])]),_c('span',{staticStyle:{"color":"#000000"}},[_vm._v("协议终止后处理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("1．")])])]),_c('span',{staticStyle:{}},[_vm._v("本协议终止后，除法律有明确规定外，橄榄医院平台无义务向您或您指定的第三方披露您账号中的任何信息。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("2．")])])]),_c('span',{staticStyle:{}},[_vm._v("本协议终止后，橄榄医院仍享有下列权利：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（1）")])])]),_c('span',{staticStyle:{}},[_vm._v("原则上对您留存的个人信息进行删除或匿名化处理，但橄榄医院有权利继续保存您留存于橄榄医院的各类信息；")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("（2）")])])]),_c('span',{staticStyle:{}},[_vm._v("对于您过往的违约行为，橄榄医院仍可依据本协议向您追究违约责任。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-left":"21pt","margin-bottom":"7.8pt","font-size":"12pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0、'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'7'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("七、")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("其他")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("（一）")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("联系方式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("当你有其他的投诉、建议、个人信息及隐私保护相关问题时，请通过橄榄医院客户端提供的在线客服系统我们联系。你也可以将你的问题寄到如下地址：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-left":"21pt","margin-bottom":"7.8pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("地址：广州市白云区金沙街沙凤三路3号B馆B129、B438/广州橄榄医院有限公司/客服部")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-left":"21pt","margin-bottom":"7.8pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("座机：4009003732转2到体检后转0到人工")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("（二）")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("协议签订地")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-left":"21pt","margin-bottom":"7.8pt","font-size":"11pt"}},[_c('span',{staticStyle:{}},[_vm._v("本协议签订地约定为广州。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"21pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'（%0）'","-aw-list-number-styles":"'chineseCounting'","-aw-list-number-values":"'3'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_c('span',[_vm._v("（三）")])])]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("争议解决方式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'1'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("1．")])])]),_c('span',{staticStyle:{}},[_vm._v("本协议的解释、效力及纠纷的解决，适用于中华人民共和国法律。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt","-aw-import":"list-item","-aw-list-level-number":"0","-aw-list-number-format":"'%0．'","-aw-list-number-styles":"'decimal'","-aw-list-number-values":"'2'"}},[_c('span',{staticStyle:{"-aw-import":"ignore"}},[_c('span',{staticStyle:{}},[_c('span',[_vm._v("2．")])])]),_c('span',{staticStyle:{}},[_vm._v("若您和广州橄榄医院有限公司之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，您在此完全同意将纠纷或争议提交深圳国际仲裁院仲裁。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"7.8pt","margin-bottom":"7.8pt","text-indent":"22pt","font-size":"11pt"}},[_c('span',{staticStyle:{"color":"#333333","-aw-import":"ignore"}},[_vm._v(" ")])])
}]

export { render, staticRenderFns }