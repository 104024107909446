<template>
  <div style="overflow-y: scroll">
    <div class="main">
      <base-product
        :productInfo="goodDetail"
        :projects="projects"
        :balance="balance"
        :addItemPrice="addItemPrice"
        :showBalance="!!discount && groupInfo.examMethod === 2"></base-product>
      <div class="form-container">
        <div class="item">
          <span>预约时间</span>
          <span style="color: #8c9196" @click="showCalendar = true"
            >{{
              $store.state.appointmentTime.date
                ? $store.state.appointmentTime.date +
                  " " +
                  $store.state.appointmentTime.time
                : "请选择时间"
            }}
            <van-icon style="margin-left: 16px" name="arrow-down"
          /></span>
        </div>
      </div>
      <div class="form-container">
        <div class="header-title">体检人信息</div>
        <div class="item">
          <span>姓名</span>
          <input
            v-model="memberName"
            type="text"
            placeholder="请填写体检人真实姓名" />
        </div>
        <div class="item">
          <span>证件</span>
          <span style="color: #8c9196" @click="certificateShow = true"
            >{{ certificateActions[certificateIndex].name
            }}<van-icon style="margin-left: 16px" name="arrow-down"
          /></span>
          <van-action-sheet
            v-model="certificateShow"
            :actions="certificateActions"
            cancel-text="取消"
            close-on-click-action
            @select="onSelectCertificate" />
        </div>
        <div class="item">
          <span>证件号</span>
          <input
            v-model="certificateNo"
            type="text"
            maxlength="18"
            placeholder="请填写证件号" />
        </div>
        <div class="item">
          <span>出生日期</span>
          <input
            v-if="certificateOfType === '1'"
            v-model="birthday"
            readonly
            placeholder="自动获取" />
          <span v-else @click="showBirthdayPicker = true">
            {{ birthday || "请选择出生日期"
            }}<van-icon style="margin-left: 16px" name="arrow-down" />
          </span>

          <van-popup v-model="showBirthdayPicker" position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              :min-date="minDate"
              :max-date="maxDate"
              title="选择出生日期"
              @cancel="showBirthdayPicker = false"
              @confirm="confirmBirthday" />
          </van-popup>
        </div>
        <div class="item">
          <span>性别</span>
          <van-radio-group
            v-model="sex"
            direction="horizontal"
            checked-color="#00c4b3">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="form-container">
        <div class="item">
          <span>手机号</span>
          <input
            v-model="mobile"
            type="number"
            maxlength="11"
            placeholder="请输入手机号" />
        </div>
        <div class="item">
          <span>婚姻状况</span>
          <van-radio-group
            v-model="maritalState"
            direction="horizontal"
            checked-color="#00c4b3">
            <van-radio name="1">已婚</van-radio>
            <van-radio name="2">未婚</van-radio>
          </van-radio-group>
        </div>
      </div>

      <template
        v-if="
          !goodDetail.payeeCompany && ![546762464538693, 546483034869829].includes(goodDetail.hospitalId)
        ">
        <invoice-popup
          :invoiceType="invoiceType"
          @invoiceTypeChange="invoiceTypeChange"></invoice-popup>
      </template>

      <calendar
        :hospitalProductId="id"
        :defaultDate="defaultDate"
        :showPopup="showCalendar"
        @onClosePopup="showCalendar = false"
        @onConfirm="onConfirmDate"></calendar>
    </div>

    <bottom
      :totalPrice="totalPrice"
      :invoiceType="invoiceType"
      :discount="discount"
      :needKown="needKown"
      :payeeCompany="goodDetail.payeeCompany"
      @needKownChange="(checked) => (needKown = checked)"
      @lookDiscount="showDetail = true"
      @submitOrder="submitOrder"></bottom>

    <van-popup
      v-model="showDetail"
      :lazy-render="false"
      get-container="body"
      position="bottom"
      round
      closeable>
      <div class="popup-detail-header">金额明细</div>
      <div class="popup-detail-main">
        <div class="popup-detail-title">套餐名称</div>

        <div class="popup-detail-product">
          <span>{{ goodDetail.productName }}</span>
          <span style="color: #333" class="bold">¥{{ goodDetail.salePrice }}</span>
        </div>

        <template v-if="projects && projects.length">
          <div style="margin-top: 10px" class="popup-detail-title">
            <span class="bold">套餐加项</span>
            <span class="bold">￥{{ addItemPrice }}</span>
          </div>
          <div
            class="popup-detail-product"
            v-for="item in projects"
            :key="item.projectId">
            <span>{{ item.projectName }}</span>
          </div>
        </template>

        <div class="popup-detail-product">
          <span class="popup-detail-title">商品总价</span>
          <span class="popup-detail-price">¥{{ salePrice }}</span>
        </div>

        <div class="popup-detail-product" v-if="discount">
          <span class="popup-detail-title">{{
            groupInfo.examMethod === 1 ? "企业指定套餐" : "企业团检金"
          }}</span>
          <span class="popup-detail-price">抵扣 ¥{{ discount }}</span>
        </div>

        <div class="popup-detail-product">
          <span class="popup-detail-title">{{ goodDetail.payeeCompany ? '线下付款：':'实付款：' }}</span>
          <span class="popup-detail-price">¥{{ totalPrice }}</span>
        </div>
      </div>
      <bottom
        :totalPrice="totalPrice"
        :invoiceType="invoiceType"
        :discount="discount"
        :needKown="needKown"
        :payeeCompany="goodDetail.payeeCompany"
        @needKownChange="(checked) => (needKown = checked)"
        @submitOrder="submitOrder"></bottom>
    </van-popup>
  </div>
</template>

<script>
import baseProduct from "./components/baseProduct.vue";
import invoicePopup from "./components/invoicePopup.vue";
import calendar from "@/components/calendar.vue";
import bottom from "@/views/components/confirm-order/bottom.vue";
import { isWechat, checkIdCard } from "@/utils/index.js";

import {
  GetProductItem,
  SubmitOrder,
  OrdersGetToken,
  QueryStatus,
  CalcAddtionPackageAmount,
  PostStatistics,
} from "@/api";

import dayjs from "dayjs";
export default {
  name: "confirm-order",
  components: {
    baseProduct,
    invoicePopup,
    calendar,
    bottom,
  },
  computed: {
    openid() {
      return this.$store.state.openid;
    },
    addItem() {
      return JSON.parse(localStorage.getItem("addItem"));
    },
    projects() {
      return JSON.parse(localStorage.getItem("projects"));
    },
    userToken() {
      return this.$store.state.token;
    },
    defaultDate() {
      return this.$store.state.appointmentTime || {};
    },
    salePrice() {
      return ((this.goodDetail.salePrice || 0) + this.addItemPrice).toFixed(2);
    },
    totalPrice() {
      return (this.salePrice - this.discount).toFixed(2);
    },
    discount() {
      let discount = 0;
      const products = this?.groupInfo?.productIds?.split(",");
      const isDiscountProduct = products?.includes(
        String(this.goodDetail?.productId)
      );
      if (!this.groupInfo.isUsed && isDiscountProduct) {
        if (this.groupInfo.examMethod === 1) {
          //指定套餐类型
          discount = this.goodDetail.salePrice;
        }

        if (this.groupInfo.examMethod === 2) {
          //金额类型
          if (this.salePrice - this.groupInfo.amount > 0) {
            discount = this.groupInfo.amount;
          } else {
            discount = this.salePrice;
          }
        }
      }

      return discount;
    },
    balance() {
      let balance = 0;
      if (
        this.groupInfo.examMethod === 2 &&
        this.groupInfo.amount - this.salePrice > 0
      ) {
        balance = (this.groupInfo.amount - this.salePrice).toFixed(2);
      }
      return balance;
    },
  },
  watch: {
    certificateNo(newValue) {
      if (this.certificateOfType === "1" && newValue.length === 18) {
        let birth = newValue.substr(6, 8).split("");
        birth.splice(4, 0, "-");
        birth.splice(7, 0, "-");
        birth = birth.join("");
        const sex = +newValue.substr(16, 1) % 2 === 1 ? "1" : "2";
        console.log(birth, sex);
        this.birthday = birth;
        this.currentDate = new Date(birth);
        this.sex = sex;
      }
    },
    userToken: {
      handler(newValue) {
        console.log(newValue, "token");
        if (newValue) {
          this.sendStatistics(4);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      memberName: "", //体检人姓名
      certificateOfType: "1", //证件类型 身份证 1 护照 2
      certificateNo: "", //证件号码
      certificateShow: false,
      certificateActions: [
        { name: "身份证", certificateOfType: "1" },
        { name: "其他", certificateOfType: "2" },
      ],
      certificateIndex: 0,
      birthday: "", //出生日期
      sex: "", //性别
      mobile: "", //手机号
      maritalState: "", //婚姻状况 已婚 1 ，未婚 2
      invoiceType: "1",
      showCalendar: false,
      showBirthdayPicker: false,
      minDate: new Date("1920/01/01"),
      maxDate: new Date(),
      currentDate: new Date(),
      id: "",
      needKown: false,
      goodDetail: {},
      showPopup: false,
      payInfo: {},
      addItemPrice: 0,
      token: "",
      showDetail: false,
      groupInfo: {},
    };
  },
  created() {
    this.$store.commit("setTitle", "确认订单");
    this.id = this.$route.params.id;
    this.geDetail();
    this.$nextTick(() => {
      this.mobile = this.$store.state.mobile || "";
    });
    if (this.$store.state.hospitalId == "8501") {
      this.certificateActions = [{ name: "身份证", certificateOfType: "1" }];
    }
  },
  methods: {
    async sendStatistics(trackingType) {
      const openId = localStorage.getItem("openid");
      if (!openId || !this.userToken) return;
      this.id = this.$route.params.id;
      await PostStatistics({
        openId: openId,
        hospitalId: this.$store.state.hospitalId,
        hospitalProductId: this.id,
        trackingType,
      });
    },
    onSelectCertificate(item, index) {
      this.certificateOfType = item.certificateOfType;
      this.certificateIndex = index;
      this.certificateShow = false;
    },
    invoiceTypeChange(name) {
      this.invoiceType = name;
    },
    onClose() {
      this.showCalendar = false;
    },
    confirmBirthday(value) {
      this.birthday = dayjs(value).format("YYYY-MM-DD");
      this.showBirthdayPicker = false;
    },
    validate(params) {
      const {
        memberName,
        certificateNo,
        certificateOfType,
        birthday,
        sex,
        mobile,
        maritalState,
        appointmentDate,
        appointmentTime,
      } = params;
      if (!memberName) {
        this.$toast("请输入体检人姓名");
        return false;
      } else if (!certificateNo) {
        this.$toast("请输入证件号");
        return false;
      } else if (certificateOfType === "1" && !checkIdCard(certificateNo)) {
        this.$toast("请输入正确的身份证");
        return false;
      } else if (!birthday) {
        this.$toast("请选择出生日期");
        return false;
      } else if (!sex) {
        this.$toast("请选择体检人性别");
        return false;
      } else if (!mobile && mobile.length !== 11) {
        this.$toast("请输入正确的体检人手机号");
        return false;
      } else if (!maritalState) {
        this.$toast("请选择体检人婚姻状况");
        return false;
      } else if (!appointmentDate || !appointmentTime) {
        this.$toast("请选择预约时间");
        return false;
      } else if (!this.needKown) {
        this.$toast("请勾选协议");
        return false;
      }
      return true;
    },
    async submitOrder() {
      let addtionalItemCodes = [];
      if (this.projects && this.projects.length) {
        addtionalItemCodes = this.getItemCodes();
      }
      const params = {
        memberName: this.memberName, //体检人姓名
        certificateOfType: this.certificateOfType, //证件类型 身份证 1 护照 2
        certificateNo: this.certificateNo, //证件号码
        birthday: this.birthday, //出生日期
        sex: this.sex, //性别
        mobile: this.mobile, //手机号
        maritalState: this.maritalState,
        appointmentDate: this.$store.state.appointmentTime.date,
        appointmentTime: this.$store.state.appointmentTime.time,
        hospitalProductId: this.id,
        addtionalItemCodes,
        openId: this.openid,
        paymentType: 0, // 支付平台 Tenpay = 0 Alipay = 1
        paymentMethod: isWechat() ? 1 : 2, // JsApi = 1  H5 = 2 Pc = 3
        recommendShareKey: sessionStorage.getItem('recommendShareKey')
      };
      console.log(params);
      if (!this.validate(params)) {
        return;
      }

      this.token = await OrdersGetToken();
      params.token = this.token;
      let data = await SubmitOrder(params);
      console.log(data, "下单请求");
      if (data.requiredPay) {
        this.payInfo = data;
        if (data.h5Url) {
          window.location.href = data.h5Url;
        } else {
          this.weixinPay();
        }
      } else {
        this.$router.replace("/order-detail/" + data.orderNo);
      }
    },
    async geDetail() {
      const params = {
        itemId: this.id,
      };
      const data = await GetProductItem(params);
      this.goodDetail = data;
      this.calcPackagesAmount();
    },
    onBridgeReady() {
      const _this = this;
      console.log(_this.payInfo);
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: _this.payInfo.appId, //公众号名称，由商户传入
          timeStamp: _this.payInfo.timestamp, //时间戳，自1970年以来的秒数
          nonceStr: _this.payInfo.nonceStr, //随机串
          package: _this.payInfo.prepayIdPackage,
          signType: "RSA", //微信签名方式：
          paySign: _this.payInfo.signature, //微信签名
        },
        async (res) => {
          let { payStatus, appointmentStatus } = await this.QueryStatus();
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // _this.$toast('支付成功')
            // this.gametwoApplyWx()
            // webPayReturnToOrder({gameOrderId: _this.payInfo.gameOrderId})
            // _this.$router.push('/success')
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          } else {
            console.log(res.err_msg, "wx_pay");
            // _this.$toast('支付失败,请重新支付')
          }
          _this.$router.replace("/order-detail/" + _this.payInfo.orderNo);
        }
      );
    },
    weixinPay() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    async QueryStatus() {
      let data = await QueryStatus({ orderNo: this.payInfo.orderNo });
      return data;
    },
    onConfirmDate() {},
    async calcPackagesAmount() {
      const additionProjects = this.getItemCodes();
      const res = await CalcAddtionPackageAmount({
        hospitalProductId: this.id,
        additionProjects,
      });

      if (res.validateSuccess) {
        const { addPriceInfo } = res;
        if (addPriceInfo) {
          this.addItemPrice = addPriceInfo.addTotalPrice;
        }
      }
    },
    getItemCodes() {
      const itemCodeList = Array.from(
        new Set(this.projects.map((item) => item.itemCode))
      );
      const additionProjects = itemCodeList.map((itemCode) => {
        const subChildrenNos = [];
        this.projects.forEach((projects) => {
          if (projects.itemCode === itemCode) {
            subChildrenNos.push(projects.projectNo);
          }
        });
        return {
          packageNo: itemCode,
          subChildrenNos,
        };
      });
      return additionProjects;
    },
  },
};
</script>

<style>
body {
  background-color: #f6f6f6;
}
</style>
<style lang="scss" scoped>
.main {
  padding: 8px;
}

.form-container {
  margin-top: 8px;
  background-color: #fff;
  border-radius: 8px;
}
.header-title {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 16px;
  font-size: 16px;
  color: #8c9196;
  height: 40px;
}

.header-title::after,
.item::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #e7e7e7;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleY(0.5);
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 40px;
  margin: 0 16px;
  font-size: 16px;
}

.item input {
  text-align: right;
}

.popup-detail-header {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #2c2f37;
  text-align: center;
}

.popup-detail-main {
  padding: 20px 20px 36px 20px;
}

.popup-detail-title {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-weight: bold;
  color: #333333;
}

.popup-detail-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #878d99;
}

.popup-detail-price {
  font-size: 13px;
  font-weight: bold;
  color: #ff3d00;
}
</style>
