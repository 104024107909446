<template>
        <div>
        <p class="Title" style="font=size:24pt;margin-top:7.8pt; margin-bottom:7.8pt; text-align:justify;"><a
                name="_Toc25896"></a><a name="_Toc21493"><span style="font-family:;font-size:24pt;">用户隐私政策</span><span
                    style="-aw-bookmark-end:_Toc25896"></span></a></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:right; font-size:11pt"><span
                style="font-family:">版本号：00003</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:right; font-size:11pt"><span
                style="font-family:">更新时间：2024年8月21日</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; font-size:14pt"><span
                style="font-family:; font-weight:bold">感谢您选择使用我们的产品和服务！</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">“我们”是广州橄榄医院有限公司，注册地位于广州市白云区金沙街沙凤三路3号B馆B129、B438。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">“我们的服务”是指我们通过我们平台的客户端（包括但不限于H5网页、小程序、PC端，下称“本平台”）向您提供的互联网平台信息技术服务。我们的服务由我们和我们分布于各地的各分支机构、关联公司及合作伙伴共同为您提供。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">广州橄榄医院有限公司（下称“橄榄医院”）深知个人信息对您的重要性，并会尽全力保护您的个人信息，我们致力于维持您对我们的信任，格守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。同时我们承诺：将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息，</span><span
                style="font-family:; font-weight:bold">请在使用我们的服务前，仔细阅读并了解本用户隐私政策。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">我们的部分服务基于其特殊原因及法律规定，可能会有独立的隐私政策，您在使用这些特定的服务时，除遵守本《用户隐私政策》外，还应当遵守相关服务所对应的独立的隐私政策，如相关服务所对应的独立的隐私政策中规定的内容与本隐私政策发生冲突，则在该服务中，以其所对应的独立的隐私政策的规定为准。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">如果您有任何疑问、意见或建议，可按照本政策之“八、如何联系我们”载明的方式与我们联系。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; font-size:14pt"><span
                style="font-family:; font-weight:bold">目录</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; font-size:11pt"><span
                style="font-family:">我们的用户隐私政策将帮助您了解以下内容：</span><span style="-aw-field-start:true"></span><span
                style="-aw-field-code:'TOC \\o &quot;1-1&quot; \\n  \\h \\u '"></span><span
                style="-aw-field-separator:true"></span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt"><a href="#_Toc6647"
                style="text-decoration:none"><span style="font-family:; font-weight:bold; color:#000000">一、
                    业务功能的个人信息使用规则</span></a></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt"><span
                style="font-family:; font-weight:bold">二、 我们如何使用 Cookie 和同类技术</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt"><a href="#_Toc14385"
                style="text-decoration:none"><span style="font-family:; font-weight:bold; color:#000000">三、
                    我们如何存储和保护您的个人信息</span></a></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt"><a href="#_Toc5295"
                style="text-decoration:none"><span style="font-family:; font-weight:bold; color:#000000">四、
                    您的权利</span></a></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt"><a href="#_Toc482"
                style="text-decoration:none"><span style="font-family:; font-weight:bold; color:#000000">五、
                    我们如何处理儿童的个人信息</span></a></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt"><a href="#_Toc31158"
                style="text-decoration:none"><span style="font-family:; font-weight:bold; color:#000000">六、
                    您的个人信息如何在全球范围转移</span></a></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt"><a href="#_Toc15776"
                style="text-decoration:none"><span style="font-family:; font-weight:bold; color:#000000">七、
                    本隐私政策如何更新</span></a></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt"><a href="#_Toc29349"
                style="text-decoration:none"><span style="font-family:; font-weight:bold; color:#000000">八、
                    如何联系我们</span></a></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt"><span style="-aw-field-end:true"></span><span
                style="font-family:; font-size:11pt; -aw-import:ignore">&#xa0;</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; widows:2; orphans:2; font-size:14pt"><span
                style="font-family:; font-weight:bold">隐私政策具体内容</span></p>
        <h1
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:28pt;  font-size:14pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'1'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>一、</span></span></span><a
                name="_Toc22251"></a><a name="_Toc6647"><span style="font-family:">业务功能的个人信息使用规则</span><span
                    style="-aw-bookmark-end:_Toc22251"></span></a></h1>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:24pt; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'1'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>（一）</span></span></span><span
                style="font-family:; font-weight:bold">我们如何收集您的个人信息</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">在您使用我们的产品和服务时，我们根据合法、正当、必要的原则，仅收集实现产品功能所必要的信息。</span></p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-family:; font-size:11pt; font-weight:bold; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>帮助您成为我们的注册用户及账户管理</span></li>
        </ol>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">为向您提供账户注册、登录功能及服务，在您通过以下方式注册或登录本平台时，您需要提供如下账户注册信息：</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">登录：当您登录时，我们会收集您的</span><span
                style="font-family:; font-weight:bold">手机号码及验证码</span><span
                style="font-family:">，您也可以</span><span style="font-family:; font-weight:bold">微信授权登录</span><span
                style="font-family:">，用于账户管理及登录验证。</span><span
                style="font-family:; font-weight:bold">在您使用我们的服务时，我们也会根据服务内容的需要，向您收集必要的信息，并在收集时向您做出明确的提示。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">我们的部分服务可能需要您提供特定的个人敏感信息来实现特定功能。若您选择不提供该类信息，则可能无法正常使用服务中的特定功能，但不影响您使用服务中的其他功能。若您主动提供您的个人敏感信息即表示您同意我们按本政策所述目的和方式来处理您的个人敏感信息。</span>
        </p>
        <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt; font-family:; font-size:11pt; font-weight:bold; list-style-position:inside; -aw-list-padding-sml:6.83pt">
                <span
                    style="width:6.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;&#xa0;
                </span><span>为您提供平台搜索功能</span></li>
        </ol>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">当您使用本平台提供的搜索功能时，我们会收集您查询的关键字信息以及您在使用我们的服务时所阅览或要求的其他信息和内容详情。为了给您带来更便捷的搜索服务并不断完善我们的服务，我们可能会使用浏览器网络存储机制和应用数据缓存，收集您设备上的信息并进行本地存储。关键词信息通常无法单独识别您的个人身份，不属于您的个人信息，不在本政策的限制范围内。只有当您的搜索关键词信息与您的其他信息有联结并可识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本政策对其进行处理与保护。</span><span
                style="font-family:; font-weight:bold">如果您不提供上述权限、信息，将会影响您使用此功能。</span></p>
        <ol start="3" type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-left:0.25pt; margin-bottom:7.8pt; text-indent:20.7pt; font-family:; font-size:11pt; font-weight:bold; list-style-position:inside; -aw-list-padding-sml:6.88pt">
                <span
                    style="width:6.88pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;&#xa0;
                </span><span>为您提供体检预约服务功能</span></li>
        </ol>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">在体检预约服务的过程中，根据</span><span
                style="font-family:;">【{{hospitalInfo.name || hospitalInfo.hospitalName}}】建档及建立医疗关系的要求，</span><span
                style="font-family:">需要您授权本平台收集您</span><span
                style="font-family:; font-weight:bold">个人的姓名、联系方式、身份证号码、出生日期、性别、婚姻状况。</span><span
                style="font-family:">其中身份证号码为体检机构确认体检人的必要信息；性别、婚姻状况可能在您具体的体检项目有所差异；出生日期为排除体检机构限制（部分机构会限制16岁以下人员进行体检）；您提供的联系方式用于接收体检预约的过程中相关服务确认短信。此外，为提供报告查询服务，</span><span
                style="font-family:; font-weight:bold">需要您授权我们平台，在确保不泄露个人隐私情况下获取、查阅您的体检报告信息。如您选择不提供或不同意我们采集、使用以上个人信息，将导致您无法使用体检预约服务。</span>
        </p>
        <ol start="4" type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-left:0.25pt; margin-bottom:7.8pt; text-indent:20.7pt; font-family:; font-size:11pt; font-weight:bold; list-style-position:inside; -aw-list-padding-sml:6.88pt">
                <span
                    style="width:6.88pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;&#xa0;
                </span><span>为您提供下单购买功能</span></li>
        </ol>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">您可以在本平台在线购买相应的HPV抗体检测产品和体检预约服务。在您使用该功能的过程中可能会需要进行支付，在支付过程中，您需要授权微信支付或支付宝进行款项结算，</span><span
                style="font-family:; font-weight:bold">微信支付或支付宝可能会收集您的第三方支付帐号(支付宝账号、微信支付账号及银行卡信息)用于付款验证</span><span
                style="font-family:">，以及收集您的收货地址信息，包括</span><span
                style="font-family:; font-weight:bold">收货人姓名、电话、所在地区、详细地址</span><span
                style="font-family:">。</span><span
                style="font-family:; font-weight:bold">如您选择不提供以上个人信息，将导致您无法使用相关的支付功能，但不影响您使用基本浏览、搜索功能。</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:24pt; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'2'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>（二）</span></span></span><span
                style="font-family:; font-weight:bold">我们在您使用服务时获取的信息</span></p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-left:0.25pt; margin-bottom:7.8pt; text-indent:20.75pt; font-family:; font-size:11pt; font-weight:bold; list-style-position:inside; -aw-list-padding-sml:6.83pt">
                <span
                    style="width:6.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;&#xa0;
                </span><span>日志信息</span></li>
        </ol>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">当您使用我们的服务时，我们可能会自动收集相关信息并存储为服务日志信息。</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'1'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>(1)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">软件信息</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">例如，软件的版本号、浏览器类型。为确保操作环境的安全或提供服务所需，我们会收集有关您使用的移动应用和其他软件的信息。</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'2'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>(2)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">服务日志信息</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">例如，您在使用我们服务时搜索、查看的信息、服务故障信息、引荐网址等信息。</span></p>
        <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-left:0.25pt; margin-bottom:7.8pt; text-indent:20.75pt; font-family:; font-size:11pt; font-weight:bold; list-style-position:inside; -aw-list-padding-sml:6.83pt">
                <span
                    style="width:6.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;&#xa0;
                </span><span>位置信息</span></li>
        </ol>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">当您使用与位置有关的服务时，我们可能会记录您设备所在的位置信息，以便为您提供相关服务。</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'1'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>(1)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">我们会收集您的</span><span
                style="font-family:; font-weight:bold">（基于GPS和网络）</span><span
                style="font-family:">位置信息（我们仅收集您当时所处的地理位置，但不会用以判断您的行踪轨迹）来判断您所处的地点，以自动为您推荐您所在区域可以提供的产品/服务。</span><span
                style="font-family:; font-weight:bold">如果您不提供上述信息，将会影响您使用此功能。</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'2'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>(2)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:">您或其他用户在使用服务时提供的信息中可能包含您所在地理位置信息，例如您提供的帐号信息中可能包含的您所在地区信息，您或其他人共享的照片包含的地理标记信息。这些信息并非该业务功能运行所必需，但这些信息对改善服务质量、研发新产品或服务等有非常重要的意义。</span><span
                style="font-family:; font-weight:bold">如果您不提供上述信息，将会影响您使用此功能。</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:24pt; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'3'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>（三）</span></span></span><span
                style="font-family:; font-weight:bold">我们如何使用您的个人信息</span></p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-family:; font-size:11pt; font-weight:bold; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>如何使用必要信息</span></li>
        </ol>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">我们将根据合作的医疗机构的授权并按基于上述功能及主要目的使用您的个人信息，当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。</span>
        </p>
        <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-family:; font-size:11pt; font-weight:bold; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>例外情形</span></li>
        </ol>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:justify; font-size:11pt"><span
                style="font-family:; font-weight:bold">当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。但以下情形中除外：</span></p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:justify; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'1'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(1)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">与个人信息控制者履行法律法规规定的义务与职责相关的；</span></p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:justify; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'2'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(2)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">与国家安全、国防安全直接相关的；</span></p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:justify; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'3'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(3)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">与公共安全、公共卫生、重大公共利益直接相关的；</span></p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:justify; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'4'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(4)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">与刑事侦查、起诉、审判和判决执行等直接相关的；</span></p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:justify; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'5'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(5)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:; font-weight:bold">出于紧急情况下维护个人信息主体或其他个人的生命健康、财产安全等重大合法权益但又很难得到本人授权同意的；</span>
        </p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:justify; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'6'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(6)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">所涉及的个人信息是个人信息主体自行向社会公众公开的；</span></p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:justify; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'7'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(7)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">根据个人信息主体要求签订和履行合同所必需的；</span></p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:justify; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'8'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(8)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:; font-weight:bold">从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span></p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:justify; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'9'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(9)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:; font-weight:bold">维护所提供产品及/或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；</span></p>
        <p class="NormalWeb"
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:justify; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'10'; -aw-list-padding-sml:0.44pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(10)</span></span><span
                    style="width:0.44pt; font:7pt 'Times New Roman'; display:inline-block"> </span></span><span
                style="font-family:; font-weight:bold">法律、法规规定的其他情形。</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:24pt; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'4'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>（四）</span></span></span><span
                style="font-family:; font-weight:bold">我们如何委托处理、共享、转让、公开披露您的个人信息</span></p>
        <ol type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-family:; font-size:11pt; font-weight:bold; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>委托处理</span></li>
        </ol>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">平台中某些具体的模块或功能由其他服务提供商或其他合作伙伴提供，包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本用户隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</span>
        </p>
        <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-family:; font-size:11pt; font-weight:bold; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>共享</span></li>
        </ol>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">我们不会与本公司以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：</span></p>
        <p
            style="margin-top:7.8pt; margin-left:0.25pt; margin-bottom:7.8pt; text-indent:20.7pt; widows:2; orphans:2; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'1'; -aw-list-padding-sml:7.61pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>(1)</span></span><span
                    style="width:7.61pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">在</span><span
                style="font-family:; font-weight:bold">获取明确同意的情况下共享</span><span
                style="font-family:">：获得您的明确同意后，我们会与其他方共享您的个人信息，我们将在您使用我们的服务时，以独立的条款/政策如实告知您信息接收方的姓名或者名称、联系方式、处理目的、处理方式和个人信息的种类。</span>
        </p>
        <p
            style="margin-top:7.8pt; margin-left:0.25pt; margin-bottom:7.8pt; text-indent:20.7pt; widows:2; orphans:2; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'2'; -aw-list-padding-sml:7.61pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>(2)</span></span><span
                    style="width:7.61pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:; font-weight:bold">我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</span></p>
        <ol type="a" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; widows:2; orphans:2; font-family:; font-size:11pt; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>为提供预约问诊以及体检服务的功能，我们将向具体就医医疗机构提供您的具体信息，包括</span><span
                    style="font-weight:bold">有效证件（包括但不限于身份证）的号码、姓名、联系手机、出生日期、性别、婚姻状况</span><span>；</span></li>
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; widows:2; orphans:2; font-family:; font-size:11pt; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>若您在平台在线购买相应的产品和/或体检预约服务，您需要授权微信支付进行款项结算，微信支付可能会收集您的</span><span
                    style="font-weight:bold">第三方支付帐号(支付宝账号、微信支付账号及银行卡信息)</span><span>用于付款验证</span><span
                    style="font-weight:bold">；</span></li>
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; widows:2; orphans:2; font-family:; font-size:11pt; list-style-position:inside; -aw-list-padding-sml:6.44pt">
                <span
                    style="width:6.44pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>与我们的关联公司共享：您的个人信息可能会与我们的关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。</span>
            </li>
        </ol>
        <p
            style="margin-top:7.8pt; margin-left:0.25pt; margin-bottom:7.8pt; text-indent:20.7pt; widows:2; orphans:2; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'3'; -aw-list-padding-sml:7.61pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>(3)</span></span><span
                    style="width:7.61pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:">仅为实现本政策中声明的目的，我们的某些服务将委托授权合作伙伴提供处理。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们的合作伙伴无权将共享的个人信息用于任何其他用途。我们将与合作伙伴约定委托处理信息的目的、期限、处理方式、个人信息的种类、保护措施等，并对合作伙伴的个人信息处理活动进行监督。具体如下：</span>
        </p>
        <ol type="a" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; widows:2; orphans:2; font-family:; font-size:11pt; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>为实现第三方医疗机构或疾控中心登录以及挂号订单回传，我们将向</span><span
                    style="font-weight:bold">第三方医疗机构或疾控中心</span><span>提供就诊信息：</span><span
                    style="font-weight:bold">就诊人的姓名、身份证号、出生日期、联系方式、现居住地、所属街道、详细地址</span><span>，第三方医疗机构或疾控中心的联系方式您可以拨打客服电话与我们联系，我们的客服将为您提供，您可以将您的问题寄到广州市白云区金沙街沙凤三路3号B馆B129、B438/广州橄榄医院有限公司/客服部
                    邮编：518000进行询问，或者拨打座机4009003732转2到体检后转0到人工进行咨询；</span></li>
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; widows:2; orphans:2; font-family:; font-size:11pt; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>为实现发送短信功能，我们可能向短信供应商提供您的：</span><span style="font-weight:bold">姓名，手机号，挂号信息</span></li>
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; widows:2; orphans:2; font-family:; font-size:11pt; list-style-position:inside; -aw-list-padding-sml:6.44pt">
                <span
                    style="width:6.44pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>为实现网上支付功能，我们可能向银行提供您的：</span><span
                    style="font-weight:bold">身份证图片，姓名，性别，手机号，银行卡号，身份证号，身份证有效期，联系地址，职业，国籍</span><span>；</span></li>
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; widows:2; orphans:2; font-family:; font-size:11pt; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>为了实现微信信息推送，我们可能向微信提供您的：</span><span
                    style="font-weight:bold">就诊人姓名，订单编号，挂号时间</span><span>；</span></li>
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; widows:2; orphans:2; font-family:; font-size:11pt; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>为实现电话咨询功能，我们可能向华为、腾讯、云信提供您的</span><span style="font-weight:bold">手机号码</span><span>。</span>
            </li>
        </ol>
        <ol start="3" type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-family:; font-size:11pt; font-weight:bold; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>转让</span></li>
        </ol>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情形除外：</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'1'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(1)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:; font-weight:bold">在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'2'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(2)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:; font-weight:bold">在涉及合并、收购或破产清算时，如涉及个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此用户隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span>
        </p>
        <ol start="4" type="1" style="margin:0pt; padding-left:0pt">
            <li
                style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-family:; font-size:11pt; font-weight:bold; list-style-position:inside; -aw-list-padding-sml:5.83pt">
                <span
                    style="width:5.83pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:ignore">&#xa0;&#xa0;&#xa0;
                </span><span>公开披露</span></li>
        </ol>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">我们仅会在以下情形下，公开披露您的个人信息：</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'1'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(1)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">获得您明确同意后;</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'(%0)'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'2'; -aw-list-padding-sml:6.56pt">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>(2)</span></span><span
                    style="width:6.56pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:; font-weight:bold">基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <h1
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:28pt;  font-size:14pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'2'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>二、</span></span></span><a
                name="_Toc14385"></a><a name="_Toc6450"><span style="font-family:">我们如何使用 Cookie 和同类技术</span></a>
        </h1>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">为确保您获得更为流畅、轻松的使用体验，在您使用App及相关服务时，我们可能会通过小型数据文件“Cookie”识别您的身份，以帮助您减少信息录入的次数和频率。</span><span
                style="font-family:">&#xa0;</span><span
                style="font-family:">Cookie通常包含标识符、站点名称以及一些号码和字符，借助于Cookie，网站能够存储您在使用App时产生的数据。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">我们不会将Cookie用于本政策所述目的之外的任何用途，存储您数据的Cookie是唯一的，大部分网络浏览器都设有阻止Cookie的功能，它只能被将Cookie发布给您的域中的web服务器读取。您可根据自己的偏好管理或删除Cookie，并可随时清除设备上保存的所有Cookie，但如果您选择清除，则需要在每一次使用设备访问相关网站、浏览查看商品过程中重新进行用户设置。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <h1
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:28pt;  font-size:14pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'3'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>三、</span></span></span><span
                style="font-family:">我们如何存储和保护您的个人信息</span><span style="-aw-bookmark-end:_Toc14385"></span><span
                style="-aw-bookmark-end:_Toc6450"></span></h1>
        <h2
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt;  font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'1'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>（一）</span></span></span><span
                style="font-family:">存储地点</span></h2>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">我们依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。</span></p>
        <h2
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt;  font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'2'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>（二）</span></span></span><span
                style="font-family:">存储期限</span></h2>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">我们仅在为我们的产品及/或服务之目的所必须的最短期间内保存您的个人信息。超出必要期限后，我们将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外：对于您的体检预约相关信息，我们会在您注销您的账户后继续保存15年；对于您在我们平台上下单购买的产品及/或服务信息、交易信息，我们会在您注销您的账户后继续保存3年；除法律另有规定外，对于您的其他信息，我们会在您注销您的账户时同步删除。</span>
        </p>
        <h2
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt;  font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'3'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>（三）</span></span></span><span
                style="font-family:">技术措施与数据保护措施</span></h2>
        <h3
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt;  font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'1'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:normal"><span>1．</span></span></span><span
                style="font-family:; font-weight:normal">我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如包括技术保护手段、管理制度控制、安全体系保障等诸多方面。</span>
        </h3>
        <h3
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt;  font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'2'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:normal"><span>2．</span></span></span><span
                style="font-family:; font-weight:normal">我们的数据安全能力：我们使用的技术手段包括但不限于防火墙、加密（例如SSL）、去标识化或匿名化处理、访问控制措施等。此外，我们还会不断加强在您设备端的软件安全能力。例如，我们会在您的设备本地完成部分信息加密工作，以巩固安全传输；我们会了解您设备安装的应用信息和运行的进程信息，以预防病毒、木马等恶意程序。</span>
        </h3>
        <h3
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt;  font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'3'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:normal"><span>3．</span></span></span><span
                style="font-family:; font-weight:normal">我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在存储期限内保留您的个人信息。</span></h3>
        <h2
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt;  font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'4'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>（四）</span></span></span><span
                style="font-family:; color:#333333; background-color:#ffffff">安全事件处理</span></h2>
        <h3
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt;  font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'1'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:normal"><span>1．</span></span></span><a
                name="_Hlk88649543"><span
                    style="font-family:; font-weight:normal">互联网环境并非百分之百安全，如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，将可能导致您的合法权益受损。</span></a>
        </h3>
        <h3
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt;  font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'2'">
            <span style="-aw-bookmark-end:_Hlk88649543"></span><span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:normal"><span>2．</span></span></span><span
                style="font-family:; font-weight:normal">在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
        </h3>
        <h3
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt;  font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'3'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:normal"><span>3．</span></span></span><a
                name="_Hlk88649601"><span
                    style="font-family:; font-weight:normal">我们会尽力保护您的个人信息。我们也请您理解，任何安全措施都无法做到无懈可击。</span></a></h3>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="-aw-bookmark-end:_Hlk88649601"></span><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <h1
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:28pt;  font-size:14pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'4'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>四、</span></span></span><a
                name="_Toc5295"></a><a name="_Toc28061"><span style="font-family:">您的权利</span><span
                    style="-aw-bookmark-end:_Toc5295"></span></a></h1>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'1'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>（一）</span></span></span><span
                style="font-family:; font-weight:bold">访问您的个人信息</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:left; font-size:11pt"><span
                style="font-family:">您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以拨打客服电话与我们联系，具体方式详见本政策之“八、如何联系我们”。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供。如果您想行使数据访问权，请与我们联系，具体方式详见本报告之“八、如何联系我们”</span><span
                style="font-family:; font-size:10.5pt">。</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'2'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>（二）</span></span></span><span
                style="font-family:; font-weight:bold">更正您的个人信息</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">当您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正。可以拨打客服电话与我们联系，具体方式详见本政策之“八、如何联系我们”。我们将在15个工作日内回复您的更正请求。</span>
        </p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'3'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>（三）</span></span></span><span
                style="font-family:; font-weight:bold">删除您的个人信息</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">在以下情形中，您可以向我们提出删除个人信息的请求：</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'1'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>1．</span></span></span><span
                style="font-family:">如果我们处理个人信息的行为违反法律法规；</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'2'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>2．</span></span></span><span
                style="font-family:">如果我们收集、使用您的个人信息，却未征得您的同意；</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'3'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>3．</span></span></span><span
                style="font-family:">如果我们处理个人信息的行为违反了与您的约定;</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'4'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>4．</span></span></span><span
                style="font-family:">如果您不再使用我们的产品或服务，或您注销了账号;</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'5'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>5．</span></span></span><span
                style="font-family:">如果我们不再为您提供产品或服务。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'4'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>（四）</span></span></span><span
                style="font-family:; font-weight:bold">个人信息主体获取个人信息副本</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:left; font-size:11pt"><span
                style="font-family:">您有权获取您的个人信息副本，您可以通过本政策之“八、如何联系我们”与我们联系获得个人信息副本。</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'5'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>（五）</span></span></span><span
                style="font-family:; font-weight:bold">改变您授权同意的范围</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:left; font-size:11pt"><span
                style="font-family:">每个业务功能往往需要收集一些基本的个人信息才能得以运营，当您撤回同意后，我们后续将不再收集和使用您相应的个人信息，但请您理解，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的特定功能/服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响我们此前基于您的同意或授权而开展的个人信息处理。您有权随时改变您授权同意的范围，您可以通过本政策之“八、如何联系我们”与我们联系撤回您的授权同意。</span>
        </p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'6'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>（六）</span></span></span><span
                style="font-family:; font-weight:bold">个人信息主体注销账户</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:left; font-size:11pt"><span
                style="font-family:">您随时可注销此前注册的账户，您可以拨打客服电话与我们联系，具体方式详见本政策之“八、如何联系我们”。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:left; font-size:11pt"><span
                style="font-family:">在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:21pt; text-align:left; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'（%0）'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'7'">
            <span style="-aw-import:ignore"><span
                    style="font-family:; font-weight:bold"><span>（七）</span></span></span><span
                style="font-family:; font-weight:bold">响应您的上述请求</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求我们将在15天内作出答复。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段(例如，要开发新系统或从根本上改变现行惯例)、给他人合法权益带来风险或者非常不切实际(例如，涉及备份磁带上存放的信息)的请求，我们可能会予以拒绝。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">在以下情形中，我们将无法响应您的请求：</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'1'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>1．</span></span></span><span
                style="font-family:">与个人信息控制者履行法律法规规定的义务相关的;</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'2'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>2．</span></span></span><span
                style="font-family:">与网家安全、国防安全直接相关的;</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'3'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>3．</span></span></span><span
                style="font-family:">与公共安全、公共卫生、重大公共利益直接相关的;</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'4'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>4．</span></span></span><span
                style="font-family:">与刑事侦查、起诉、审判和执行判决等直接相关的;</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'5'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>5．</span></span></span><span
                style="font-family:">个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'6'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>6．</span></span></span><span
                style="font-family:">出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的;</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'7'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>7．</span></span></span><span
                style="font-family:">响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的;</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'8'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>8．</span></span></span><span
                style="font-family:">涉及商业秘密的。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:left; font-size:11pt"><span
                style="font-family:">若我们决定不响应您的请求，我们将向您告知该决定的理由，您也可通过本政策之“八、如何联系我们”提供的联系方式进行投诉。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <h1
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:28pt;  font-size:14pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'5'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>五、</span></span></span><a
                name="_Toc14538"></a><a name="_Toc482"><span style="font-family:">我们如何处理儿童的个人信息</span><span
                    style="-aw-bookmark-end:_Toc14538"></span></a></h1>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">我们的产品、网站和服务主要面向成人。如果您是儿童，请您通知您的监护人阅读本规则，并请您在监护人的授权同意后使用我们的产品或服务。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">我们如何收集、使用、存储和处理儿童个人信息，以及我们为监护人与儿童提供的访问、更正、删除、保护这些信息的方式可以查阅附件一《儿童个人信息保护规则》。尽管当地法律和习俗对儿童的定义不同，但我们将不满14周岁的任何人均视为儿童。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <h1
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:28pt;  font-size:14pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'6'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>六、</span></span></span><a
                name="_Toc31158"></a><a name="_Toc28520"><span style="font-family:">您的个人信息如何在全球范围转移</span><span
                    style="-aw-bookmark-end:_Toc31158"></span></a></h1>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">如部分产品或服务涉及跨境，我们需要向境外传输您的个人信息，我们会严格按照法律法规的规定执行，并保证您的个人信息安全。若部分管辖区设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移个人信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:; font-weight:bold; -aw-import:ignore">&#xa0;</span></p>
        <h1
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:28pt;  font-size:14pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'7'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>七、</span></span></span><a
                name="_Toc28360"></a><a name="_Toc15776"><span style="font-family:">本隐私政策如何更新</span><span
                    style="-aw-bookmark-end:_Toc28360"></span></a></h1>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:left; font-size:11pt"><span
                style="font-family:">我们可能会不时对本《用户隐私政策》进行修订，当本《用户隐私政策》发生修订时，修改后的政策一旦被张贴在本站上即生效，并代替原来的政策，您有权就修订后的《用户隐私政策》重新向我们授权确认。</span><span
                style="font-family:; font-weight:bold">请您注意，在《用户隐私政策》变更后，我们将无法保证按照其变更前的内容收集、使用、处理和存储您的个人信息。只有在您确认修订后的《用户隐私政策》后，我们才会按照修订后的《用户隐私政策》收集、使用、处理和存储您的个人信息。因此，如果您不同意修订后的《用户隐私政策》，请您停止使用我们的产品及/或服务。您继续使用我们产品及/或服务的行为也同样会被视为您同意并接受我们修订后的《用户隐私政策》。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">对于重大变更，我们还会提供更为显著的通知(包括对于某些服务，我们会通过电子邮件发送通知，说明用户隐私政策的具体变更内容)。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">本政策所指的重大变更包括但不限于：</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'1'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>1．</span></span></span><span
                style="font-family:">我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、，个人信息的使用方式等；</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'2'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>2．</span></span></span><span
                style="font-family:">我们在所有权结构、组织架构等方面发生重大变化，如业务调整、破产并购等引起的所有者变更等;</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'3'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>3．</span></span></span><span
                style="font-family:">个人信息共享、转让或公开披露的主要对象发生变化；</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'4'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>4．</span></span></span><span
                style="font-family:">您参与个人信息处理方面的权利及其行使方式发生重大变化；</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'5'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>5．</span></span></span><span
                style="font-family:">我们负责处理个人信息安全的责任部门、联络方式及投诉果道发生变化时;</span></p>
        <p
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0．'; -aw-list-number-styles:'decimal'; -aw-list-number-values:'6'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>6．</span></span></span><span
                style="font-family:">个人信息安全影响评估报告表明存在高风险时。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">我们还会将本政策的旧版本存档，供您查阅。</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <h1
            style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:28pt;  font-size:14pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0、'; -aw-list-number-styles:'chineseCounting'; -aw-list-number-values:'8'">
            <span style="-aw-import:ignore"><span style="font-family:"><span>八、</span></span></span><a
                name="_Toc29349"></a><a name="_Toc1001"><span style="font-family:">如何联系我们</span><span
                    style="-aw-bookmark-end:_Toc29349"></span></a></h1>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">当您有其他的投诉、建议、个人信息及隐私保护相关问题时，请您将您的问题寄到如下地址：</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">地址：广州市白云区金沙街沙凤三路3号B馆B129、B438/广州橄榄医院有限公司/客服部</span></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><a name="_Hlk88649812"><span
                    style="font-family:">座机：4009003732转2到体检后转0到人工</span></a></p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; text-align:left; font-size:11pt"><span
                style="-aw-bookmark-end:_Hlk88649812"></span><span
                style="font-family:">我们将尽快审核所涉问题，并在验证您的用户身份后的15个工作日内予以回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向深圳国际仲裁院提起仲裁。</span>
        </p>
        <p style="margin-top:7.8pt; margin-bottom:7.8pt; text-indent:22pt; font-size:11pt"><span
                style="font-family:">就您与其他平台存在的疑问、纠纷或争议，请您知悉我们仅与该等平台存在合作关系，无法控制该等平台，烦请直接联系前述平台予以处理。</span></p>
        <p style="text-align:left; font-size:11pt"><br
                style="page-break-before:always; clear:both" /></p>
        <p style="text-align:left; font-size:14pt"><span
                style="font-family:; font-weight:bold">附件一：《儿童个人信息保护规则》</span></p>
        <p style="text-align:center; page-break-inside:avoid; line-height:150%; font-size:16pt"><span
                style="font-family:; font-weight:bold">儿童个人信息保护规则</span></p>
        <p style="text-align:right; line-height:150%; font-size:12pt"><span
                style="font-family:">更新时间：2023年4月10日</span></p>
        <p style="text-align:right; line-height:150%; font-size:12pt"><span
                style="font-family:">生效时间：2023年4月10日</span></p>
        <p style="text-indent:24pt; text-align:right; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <p
            style="text-indent:24pt; page-break-inside:avoid; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0.'; -aw-list-number-styles:'decimalHalfWidth'; -aw-list-number-values:'1'; -aw-list-padding-sml:7.99pt">
            <span style="-aw-import:ignore"><span style="font-family:; font-weight:bold"><span>1.</span></span><span
                    style="width:7.99pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">特别提示</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span style="font-family:">欢迎使用</span><span
                style="font-family:; text-decoration:underline">广州橄榄医院有限公司H5网页</span><span
                style="font-family:">（以下也称“我们的产品或服务”）！我们深知儿童个人信息和隐私安全的重要性，并庄严承诺保护儿童用户的个人信息和隐私安全。我们希望通过《儿童个人信息保护规则》（以下也称“本规则”）说明我们如何收集、使用、存储和处理儿童个人信息，以及我们为监护人与儿童提供的访问、更正、删除、保护这些信息的方式。</span><span
                style="font-family:; font-weight:bold">在使用我们的产品或服务之前，请儿童、儿童的父母或其他监护人（以下称监护人）务必仔细阅读并透彻理解本规则，特别是以粗体标识的条款应重点阅读，在确认充分理解并同意全部条款后再开始使用。</span>
        </p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; font-weight:bold">监护人特别说明：</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:">儿童个人信息保护需要您和我们的携手共同努力，我们希望您协助我们保护您的孩子的个人信息，请您确保您的孩子在您的同意和指导下使用我们的产品或服务和向我们提交个人信息（如需）。您点击同意使用或继续使用我们提供的产品或服务的，</span><span
                style="font-family:; font-weight:bold">表示您同意我们按照《用户隐私政策》及本规则的约定收集、使用和处理您和您监护的儿童的个人信息。如果您不同意本规则的内容，请您要求您的孩子立即停止访问或使用我们的产品或服务。</span>
        </p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; font-weight:bold">儿童特别说明：</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; font-weight:bold">如果您是儿童，请您通知您的监护人阅读本规则，并请您在监护人的授权同意后使用我们的产品或服务。如果您的监护人不同意本规则或不同意您使用我们的产品或服务的，请您停止使用我们的产品或服务。</span>
        </p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; font-weight:bold">本规则将帮助您了解以下内容：</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; font-weight:bold">（1）我们如何收集和使用儿童个人信息</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; font-weight:bold">（2）我们如何共享、转让、公开披露儿童个人信息</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; font-weight:bold">（3）您与/或儿童对儿童个人信息的控制权</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; font-weight:bold">（4）我们如何存储和保护儿童个人信息</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; font-weight:bold">（5）如何联系我们</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; font-weight:bold; -aw-import:ignore">&#xa0;</span></p>
        <p
            style="text-indent:24pt; page-break-inside:avoid; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0.'; -aw-list-number-styles:'decimalHalfWidth'; -aw-list-number-values:'2'; -aw-list-padding-sml:7.99pt">
            <span style="-aw-import:ignore"><span style="font-family:; font-weight:bold"><span>2.</span></span><span
                    style="width:7.99pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">我们如何收集和使用儿童个人信息</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'2 1'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>2.1.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:">我们会严格履行法律规定的儿童个人信息保护义务与责任，遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则收集和使用儿童个人信息，不会收集与我们提供的产品或服务无关的儿童个人信息，不会违反法律规定和我们与您的约定收集、使用儿童个人信息。</span><span
                style="font-family:">&#xa0;</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'2 2'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>2.2.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">您主动提供信息的情形</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'2 2 1'; -aw-list-padding-sml:8.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>2.2.1.</span></span><span
                    style="width:8.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:; font-weight:bold">您在使用我们的产品或服务中主动向我们提供您的孩子的个人信息，表示您已经同意我们收集和按照本规则的内容处理您的孩子的个人信息。</span>
        </p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'2 2 2'; -aw-list-padding-sml:8.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>2.2.2.</span></span><span
                    style="width:8.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:">您可以添加宝宝信息对您的孩子进行管理。在您创建儿童专属账号的过程中，我们会收集您可能向我们提供的儿童个人信息（包括</span><span
                style="font-family:; font-weight:bold">宝宝姓名、性别、出生日期、与本人关系、现居住地、联系方式</span><span
                style="font-family:">），我们收集这些信息是用以完成儿童专属账号的创建程序，并据此提供更适合您所监护的儿童的优质内容。</span><span
                style="font-family:; font-weight:bold">我们在此善意的建议您，如无必要，请不要提供儿童的真实姓名、肖像、联系方式等信息，也不要轻易与他人共享儿童个人信息或将其公开发布。</span>
        </p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'2 2 3'; -aw-list-padding-sml:8.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>2.2.3.</span></span><span
                    style="width:8.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:">您可以为您所监护的儿童在线预约相应的产品及/或医疗服务。在产品及/或医疗预约服务的过程中，因医疗行业特殊性，为保证医疗安全，根据国家政府要求，您为您监护的儿童预约医疗服务时，需要您填写</span><span
                style="font-family:; font-weight:bold">您孩子的就诊人信息</span><span
                style="font-family:">，在这个过程中将收集</span><span
                style="font-family:; font-size:11pt; font-weight:bold">就诊人的姓名、身份证号、出生日期、联系方式、现居住地、所属街道、详细地址</span><span
                style="font-family:">。如您选择不提供或不同意我们采集、使用以上个人信息，将导致您孩子无法使用产品及/或医疗预约服务。</span></p>
        <p style="margin-left:21pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <p
            style="text-indent:24pt; page-break-inside:avoid; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0.'; -aw-list-number-styles:'decimalHalfWidth'; -aw-list-number-values:'3'; -aw-list-padding-sml:7.99pt">
            <span style="-aw-import:ignore"><span style="font-family:; font-weight:bold"><span>3.</span></span><span
                    style="width:7.99pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">我们如何共享、转让、公开披露儿童个人信息</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'3 1'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>3.1.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">共享</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'3 1 1'; -aw-list-padding-sml:8.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>3.1.1.</span></span><span
                    style="width:8.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">我们不会与第三方共享儿童个人信息，以下情形除外：</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:">（1）与监护人的共享。</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:">（2）事先已征得您的明确授权同意。</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:">（3）为了必要且合理的业务的顺利开展、满足您和儿童的要求、履行我们在相关用户协议或本规则中的义务和行使我们的权利或遵守法律规定等目的，与为我们的产品或服务提供功能支持的服务提供商的必要共享，如为提供预约产品及/或医疗服务等功能，我们将向</span><span
                style="font-family:; font-weight:bold">具体就医医疗机构</span><span style="font-family:">提供</span><span
                style="font-family:; font-weight:bold">您孩子的具体信息，包括就诊人的姓名、身份证号、出生日期、联系方式、现居住地、所属街道、详细地址。</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; font-weight:bold">但我们承诺我们只会共享儿童必要个人信息，如果我们需要改变儿童个人信息的处理目的时，将再次征求您的授权同意。</span><span
                style="font-family:">请您放心，我们会对服务商和其服务行为等进行安全评估，签署协议，明确责任、处理事项、处理期限、处理性质和目的等，要求其处理行为不得超出我们的授权范围；且请您放心，除得到您的授权同意，否则我们不会将您监护的儿童的个人信息与第三方共享用于营销活动。</span>
        </p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'3 2'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>3.2.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">转让与公开披露</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'3 2 1'; -aw-list-padding-sml:8.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>3.2.1.</span></span><span
                    style="width:8.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:">我们会严格履行法律规定的儿童个人信息保护义务与责任，遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则转让、公开披露儿童个人信息。</span>
        </p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'3 3'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>3.3.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">如您希望了解更多，请您查阅《用户隐私政策》正文第一章节。</span></p>
        <p style="margin-left:21pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <p
            style="text-indent:24pt; page-break-inside:avoid; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0.'; -aw-list-number-styles:'decimalHalfWidth'; -aw-list-number-values:'4'; -aw-list-padding-sml:7.99pt">
            <span style="-aw-import:ignore"><span style="font-family:; font-weight:bold"><span>4.</span></span><span
                    style="width:7.99pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">您与/或儿童对儿童个人信息的控制权</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'4 1'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>4.1.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">访问权</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'4 1 1'; -aw-list-padding-sml:8.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>4.1.1.</span></span><span
                    style="width:8.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">您与/或您监护的儿童可以在我们的产品或服务中查询和访问儿童的相关个人信息，例如：个人资料信息、订单状态。</span>
        </p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'4 2'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>4.2.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">修改权</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'4 2 1'; -aw-list-padding-sml:8.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>4.2.1.</span></span><span
                    style="width:8.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:">您与/或您监护的儿童可以在我们的产品或服务中修改儿童的相关个人信息，例如：个人资料信息。您可以联系我们解决，我们会在经对您的身份进行验证，且不影响信息的客观性和准确性的情况下进行修改。但出于安全性和身份识别的考虑，您可能无法修改注册时提交的某些初始注册信息。</span>
        </p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'4 2 2'; -aw-list-padding-sml:8.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>4.2.2.</span></span><span
                    style="width:8.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:">同时，如您与/或您监护的儿童发现我们收集、存储、使用、披露的儿童个人信息有错误的，可以联系我们更正，我们会在完成身份验证和核实问题后及时采取措施予以更正。</span>
        </p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'4 3'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>4.3.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">删除权</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:2; -aw-list-number-format:'%0.%1.%2.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'4 3 1'; -aw-list-padding-sml:8.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>4.3.1.</span></span><span
                    style="width:8.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:">在以下情形下，您与/或您监护的儿童可以直接向我们提出删除儿童个人信息的请求，我们会在完成身份验证和核实问题后及时采取措施予以删除，包括：</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:">（1）我们违反法律法规规定或我们与您的约定收集、存储、使用、转移、披露儿童个人信息的。</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:">（2）我们超出目的范围或者必要期限收集、存储、使用、转移、披露儿童个人信息的。</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span style="font-family:">（3）</span><span
                style="font-family:; font-weight:bold">您撤回同意的。</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:">（4）您与/或您监护的儿童通过注销等方式终止使用我们的产品与/或服务的。</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:">（5）法律法规等规定的其他情形。</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <p
            style="text-indent:24pt; page-break-inside:avoid; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0.'; -aw-list-number-styles:'decimalHalfWidth'; -aw-list-number-values:'5'; -aw-list-padding-sml:7.99pt">
            <span style="-aw-import:ignore"><span style="font-family:; font-weight:bold"><span>5.</span></span><span
                    style="width:7.99pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">我们如何存储和保护儿童个人信息</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'5 1'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>5.1.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:">我们依照法律法规的规定，将儿童个人信息存储于中华人民共和国境内。除法律法规或监管部门另有规定外，我们仅在为提供我们的产品或服务之目的所必需且最短的期间内保留儿童个人信息。在超出上述存储期限后，我们会对儿童个人信息进行删除或匿名化处理。</span>
        </p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'5 2'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>5.2.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span
                style="font-family:">我们采用了符合行业标准的安全技术措施及配套的组织架构和管理体系等多层面保护措施来保护儿童个人信息；同时，我们遵循最小授权原则，对工作人员设定了严格的信息访问权限，控制儿童个人信息知悉范围；我们也采取了必要技术措施，避免违法复制、下载儿童个人信息。</span>
        </p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'5 3'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>5.3.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">如您希望了解更多，请您查阅《用户隐私政策》正文第三章节。</span></p>
        <p style="margin-left:21pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <p
            style="text-indent:24pt; page-break-inside:avoid; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:0; -aw-list-number-format:'%0.'; -aw-list-number-styles:'decimalHalfWidth'; -aw-list-number-values:'6'; -aw-list-padding-sml:7.99pt">
            <span style="-aw-import:ignore"><span style="font-family:; font-weight:bold"><span>6.</span></span><span
                    style="width:7.99pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:; font-weight:bold">如何联系我们</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'6 1'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>6.1.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">如果您对本隐私政策有任何疑问、意见或建议，请通过以下方式与我们联系：</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:">地址：广州市白云区金沙街沙凤三路3号B馆B129、B438/广州橄榄医院有限公司/客服部</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:">座机：4009003732转2到体检后转0到人工</span></p>
        <p
            style="text-indent:24pt; widows:2; orphans:2; font-size:12pt; -aw-import:list-item; -aw-list-level-number:1; -aw-list-number-format:'%0.%1.'; -aw-list-number-styles:'decimalHalfWidth decimalHalfWidth'; -aw-list-number-values:'6 2'; -aw-list-padding-sml:18.98pt">
            <span style="-aw-import:ignore"><span style="font-family:"><span>6.2.</span></span><span
                    style="width:18.98pt; font:7pt 'Times New Roman'; display:inline-block; -aw-import:spaces">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
                </span></span><span style="font-family:">一般情况下，我们将在</span><span
                style="font-family:; text-decoration:underline">十五</span><span
                style="font-family:">个工作日内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您可以向深圳国际仲裁院提起仲裁。</span></p>
        <p style="text-indent:24pt; widows:2; orphans:2; font-size:12pt"><span
                style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <p style="text-align:left; font-size:12pt"><span style="font-family:; -aw-import:ignore">&#xa0;</span></p>
        <p style="text-indent:22pt; font-size:11pt"><span style="font-family:; -aw-import:ignore">&#xa0;</span></p>
    </div>
</template>

<script>
export default {
    computed:{
        hospitalInfo(){
            return JSON.parse(localStorage.getItem('hospitalInfo'))
        }
    }
}
</script>

<style>

</style>